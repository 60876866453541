<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">RPage Example</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button>Create</b-button>
          <b-button class="is-primary" outlined @click="toggleSidebar">
            Show Sidebar
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div>
        <div class="r-card">
          <h3>Overview</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
            excepturi unde vitae maiores at possimus nobis eos! Tenetur dolore,
            voluptatum sunt ipsa dolorum laudantium vero voluptatibus itaque,
            quis omnis rem!
          </p>
        </div>
        <r-card>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut dolorem
          et mollitia corrupti aspernatur cum alias doloremque, doloribus iure
          excepturi autem necessitatibus repellendus nemo labore eum dolor
          corporis vero voluptatem.
        </r-card>
      </div>
    </template>
    <template #page-sidebar>
      <b-sidebar position="static" right v-model="isOpen" fullheight>
        <r-card outlined full-height>
          <div class="level">
            <div class="level-left">
              <h3 class="mb-0">Sidebar Title</h3>
            </div>
            <div class="level-right">
              <b-button class="is-command is-icon" icon-left="plus"></b-button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Blanditiis culpa perferendis aspernatur doloribus consequuntur
              impedit laudantium quod non ab? Neque reiciendis doloremque error
              optio quasi aliquam voluptates molestiae sit doloribus.
            </div>
          </div>
        </r-card>
      </b-sidebar>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
